@import '~sympla-bootstrap/scss/utils';

.pages {
  @include desktop {
    padding: $spacing-regular-1 $spacing-regular-4;
  }
  padding: $spacing-small-6 0;
  background-color: $neutral-light-medium;
  flex: 1;

  &__card {
    @include desktop {
      border-radius: $radius-small;
    }
    margin-bottom: $spacing-small-6;
  }

  &__button {
    @include desktop {
      margin-top: 0;
      margin-left: $spacing-small-5;
      width: auto;
    }
    margin-top: $spacing-small-5;
    width: 100%;
  }

  &__title {
    @include desktop {
      letter-spacing: 0.24px;
      font-size: $font-medium;
    }
    margin: 0;
    line-height: 1.4;
    letter-spacing: 0.19px;
    color: $neutral-dark-pure;
    font-size: $font-under-medium;
    font-weight: $weight-bold;
  }

  &__title:first-of-type {
    @include desktop {
      width: fit-content;
    }
    width: 100%;
  }

  &__dialog-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 230px;
    height: 210px;
    margin: 0 auto;
  }

  &__dialog-footer {
    @include touch {
      flex-direction: row;
    }
  }

  &__button-footer {
    @include touch {
      flex-grow: 1;
    }
  }

  &__alert {
    @include desktop {
      min-height: rem(300px);
    }
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: rem(150px);
  }

  &__spinner {
    @include desktop {
      min-height: rem(270px);
    }
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: rem(150px);
  }

  &__table {
    @include desktop {
      margin-top: $spacing-small-6;
    }
    margin-top: $spacing-regular-1;
  }

  &__card-header {
    @include desktop {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      align-items: center;
      justify-content: space-between;
    }
    padding: $spacing-small-6;
  }

  &__card-menu {
    @include desktop {
      margin-bottom: 0;
      width: fit-content;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &--reset-margin {
    margin: 0;
  }

  &__icon-button {
    @include desktop {
      display: none;
    }
  }

  &__button-group {
    @include desktop {
      margin: 0;
      background-color: initial;
      padding: 0;
      width: fit-content;
    }
    margin-top: $spacing-small-5;
    background-color: $neutral-light-medium;
    padding: $spacing-small-5 $spacing-small-6;
  }

  &__icon {
    fill: $support-info-pure;
    cursor: pointer;
  }

  &--group-closed {
    @include touch {
      display: none;
    }
  }

  &--hide-mobile {
    @include touch {
      display: none;
    }
  }

  &__occupancy-group {
    display: flex;
    flex-grow: 1;
  }

  &__occupancy-card {
    @include desktop {
      margin-top: 0;
    }
    margin: $spacing-small-5 0 0 $spacing-regular-2;
  }

  &__occupancy-card:first-of-type {
    @include desktop {
      margin-left: $spacing-regular-5;
    }
    margin-left: 0;
  }

  &__filter-box {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 0 $spacing-small-3 $spacing-small-6;
  }

  &__filter-title {
    margin: 0;
    font-size: $font-small;
  }
}

@include touch {
  .rdt_TableHead > .rdt_TableHeadRow > div:first-child {
    display: none;
  }

  .rdt_TableHead > .rdt_TableHeadRow > div:nth-child(2) {
    padding-left: $spacing-small-3;
  }

  .rdt_TableBody > .rdt_TableRow > div:first-child {
    max-width: 40px;
    padding-right: $spacing-small-3;
    padding-left: $spacing-small-3;

    button {
      padding: 0;
      height: 24px;
      background: $neutral-light-medium;
      border-radius: $radius-full;

      svg {
        margin: auto 0;
      }
    }
  }

  .rdt_TableBody > .rdt_TableRow > .rdt_TableCell {
    padding-right: $spacing-small-5;
    padding-left: 0;
  }

  .rdt_TableBody > .rdt_TableRow > .rdt_TableCell:nth-child(2) {
    padding: 0;
  }
}
